<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col cols="12">
          <v-card
            href="https://www.toasttab.com/the-pallet"
            target="_blank"
            class="bg-gradient-warning border-radius-xl"
          >
            <div class="px-4 py-4">
              <v-row class="row">
                <v-col cols="12" class="my-auto">
                  <div class="numbers">
                    <center>
                      <h4 class="text-white text-h5 font-weight-bolder mb-0">
                        ORDER ONLINE
                      </h4>
                    </center>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h5 class="text-h5 text-typo font-weight-bold mb-0">
            Pallet Stack Craft Sandwiches
          </h5>
          <div class="d-flex">
            <p class="text-sm text-body mb-0">
              Enjoy our craft sandwiches created from house made sauces, local
              ingredients and premium Boar’s Head meats. Served with chips.
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="stack of stacks"
          :key="stack.itemName"
          xs="12"
          xl="6"
          cols="12"
        >
          <v-card class="card-shadow pa-4 border-radius-xl z-index-2">
            <div class="d-flex align-center">
              <v-card
                color="#fdb826"
                dark
                height="80"
                width="80"
                class="
                  d-flex
                  flex-shrink-0
                  justify-center
                  align-center
                  card-shadow
                  mr-4
                  border-radius-xl
                  z-index-2
                "
              >
                <h1>{{ stack.price }}</h1>
              </v-card>
              <div>
                <div class="d-flex pb-0">
                  <h6 class="text-h6 text-typo font-weight-bold">
                    {{ stack.itemName }}
                  </h6>
                </div>
                <div class="d-flex">
                  <div class="font-italic">
                    {{ stack.desc }}
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-8">
        <v-col>
          <h5 class="text-h5 text-typo font-weight-bold mb-0">
            Pallet Cleansers
          </h5>
          <div class="d-flex">
            <p class="text-sm text-body mb-0">
              Distinguish the subtle differences in our beers, enjoy your
              experience and keep the party going! Cleanse your palate and
              prepare for the next beer with our unique Pallet Cleanser menu!
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="pCi of pCs" :key="pCi.itemName" xs="12" xl="6" cols="12">
          <v-card class="card-shadow pa-4 border-radius-xl z-index-2">
            <div class="d-flex align-center">
              <v-card
                color="#fdb826"
                dark
                height="80"
                width="80"
                class="
                  d-flex
                  flex-shrink-0
                  justify-center
                  align-center
                  card-shadow
                  mr-4
                  border-radius-xl
                  z-index-2
                "
              >
                <h1>{{ pCi.price }}</h1>
              </v-card>
              <div>
                <div class="d-flex pb-0">
                  <h6 class="text-h6 text-typo font-weight-bold">
                    {{ pCi.itemName }}
                  </h6>
                </div>
                <div class="d-flex">
                  <div class="font-italic">
                    {{ pCi.desc }}
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card
            href="https://www.toasttab.com/the-pallet"
            target="_blank"
            class="bg-gradient-warning border-radius-xl"
          >
            <div class="px-4 py-4">
              <v-row class="row">
                <v-col cols="12" class="my-auto">
                  <div class="numbers">
                    <center>
                      <h4 class="text-white text-h5 font-weight-bolder mb-0">
                        ORDER ONLINE
                      </h4>
                    </center>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import axios from "axios";

export default {
  name: "FoodMenu",
  data() {
    return {
      pCs: [
        {
          itemName: "Beer Bread",
          desc: "Made Weekly with a Different WV Craft Beer | Sour Cream & Dill",
          price: "$5",
        },
        {
          itemName: "Bone-In Wings (6)",
          desc: "Sauces: Bell’s Two Hearted Buffalo | Wired Opossum Dry Rub | Honey Sriracha | Lemon Pepper Garlic",
          price: "$8",
        },
        {
          itemName: "Boneless Wings (8)",
          desc: "Sauces: Bell’s Two Hearted Buffalo | Wired Opossum Dry Rub | Honey Sriracha | Lemon Pepper Garlic",
          price: "$6",
        },
        {
          itemName: "Pallet Pretzel Bites & Beer Cheese",
          desc: "Pretzel Nuggets | Salt | Craft Beer Cheese",
          price: "$6",
        },
        {
          itemName: "Pallet Perogies",
          desc: "Potatoes | Cheese | Air Fried Golden Brown | Sour Cream",
          price: "$6",
        },
        {
          itemName: "Pallet Pakoras",
          desc: "Traditional Indian Vegetarian Appetizer | Ranch Dipping Sauce",
          price: "$6",
        },
        {
          itemName: "POWllet Pickles",
          desc: "Breaded Pickle Slices | Jalapeno Ranch Dipping Sauce",
          price: "$6",
        },

        {
          itemName: "Pallet Tots",
          desc: "Olive Oil | Salt & Pepper",
          price: "$6",
        },
        {
          itemName: "Loaded Pallet Tots",
          desc: "Olive Oil | Salt & Pepper | Loaded with Beer Cheese | Sour Cream | Bacon Bits",
          price: "$8",
        },
        {
          itemName: "Charcuterie Board for Two",
          desc: "A mix of Cured Meats | Cheeses | Nuts | Dried Fruit",
          price: "$12",
        },
        {
          itemName: "Charcuterie Party Board",
          desc: "A mix of Cured Meats | Cheeses | Nuts | Dried Fruit",
          price: "$22",
        },
      ],
      stacks: [
        {
          itemName: "The Forklift",
          desc: "Peppered Turkey | Smoked Bacon | Lacey Swiss | Jalapeno Ranch | Avocado | Lettuce | Tomato",
          price: "$12",
        },
        {
          itemName: "The Loading Dock",
          desc: "London Broil Roast Beef | Chimichurri | Lacey Swiss",
          price: "$12",
        },
        {
          itemName: "Backroom Gabagool",
          desc: "Capocollo | Sopressata | Provolone | Hot & Sweet Peppers | Red Onion | Lettuce | Tomato | Mayo | Italian",
          price: "$12",
        },
        {
          itemName: "The Bauru",
          desc: "Brazilian Roast Beef | Tomato | Pickles | Mozzarella",
          price: "$12",
        },
        {
          itemName: "The Office Manager",
          desc: "Classic Pastrami | Yellow Mustard | Rye Bread",
          price: "$12",
        },
        {
          itemName: "The Warehouse",
          desc: "Classic Rachel | Pastrami OR Turkey | Slaw | Swiss | Rye Bread",
          price: "$12",
        },
        {
          itemName: "The Depot",
          desc: "Classic Cuban | Ham | Salami | Pork | Swiss | Pickles | Mustard",
          price: "$12",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
